import React from 'react';

const LandingPage = () => {
  return (
    <div className="bg-gray-100 h-screen flex justify-center items-center">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold mb-4">Aspyn is coming soon!</h2>
        <p>Stay tuned for something amazing.</p>
      </div>
    </div>
  );
};

export default LandingPage;
