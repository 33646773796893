import React, { useState } from 'react';

const Login = () => {
  const [tenant, setTenant] = useState('');
  const [error, setError] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (tenant.toLowerCase() === 'aptive') {
      window.location.href = 'https://aptive.aspyn.io';
    } else {
      setError('Invalid tenant name');
    }
  };

  return (
    <div className="h-screen flex items-center justify-center bg-gray-100">
      <div className="container mx-auto max-w-md p-6 bg-white shadow-md rounded">
        <h2 className="text-2xl font-bold mb-4 text-center">Sign In</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="tenant" className="block text-sm font-bold mb-2">Tenant</label>
            <input
              type="text"
              id="tenant"
              value={tenant}
              onChange={(e) => setTenant(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
              placeholder="Enter your tenant"
            />
          </div>
          {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
          <button
            type="submit"
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
          >
            Sign In
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
